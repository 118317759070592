<template>
  <Modal :isOpen="true" @onClose="closePopup"  :showBackButton="true">
    <h2 class="title text_navy">
      Select Colour
    </h2>
                <div class="container">
                    <section>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <form action="" class="form" v-on:submit.prevent>
                                    <label class="form__label">
                                        <input type="text" class="form__input"
                                               placeholder="Type to Search"
                                               name="search color"
                                               v-model="color"
                                        >
                                    </label>
                                    <button type="button" class="btn_link">
                                        <svg-icon name="search"/>
                                    </button>
                                </form>
                            </div>
                        </div>

                        <section class="item-types">
                            <div class="row">
                                <div class="col-lg-2 col-md-3 col-sm-3 col-6"
                                     v-for="colour in defaultColors"
                                     :key="colour.id"
                                     @click="selectColors(colour, colour.is_same_as_frame)"
                                >
                                    <div class="box">
                                        <div class="colours colour_box">
                                            <img class="" :src="colour.image" alt="color.colour_name"
                                                 v-if="colour.image">
                                            <p v-else-if="colour.hexcode">
                                                <svg-icon name="palette" class="large_icon box__img"
                                                                   :style="{color: colour.hexcode}"/>
                                            </p>
                                            <p v-if="!colour.image && !colour.hexcode">
                                                <svg-icon name="palette" class="large_icon box__img"/>
                                            </p>
                                        </div>
                                        <p v-if="colour.is_same_as_frame" class="text_default">
                                            <b class="text_red">Same as Frame</b><br/>({{ colour.colour_name }})
                                        </p>
                                        <p v-else class="text_default">{{ colour.colour_name }}<br/>(default)</p>
                                    </div>

                                </div>
                            </div>
                        </section>


                        <div class="divider">
                            <p class="divider__text text_green">OR SELECT BELOW</p>
                        </div>
                    </section>
                    <section class="item-types">
                        <div class="row"
                             v-for="(item, key, index) in getColoursAvailableItems"
                        >
                            <div class="col-12">
                                <h2 class="titlePage_subtitle titlePage">{{key}}</h2>
                            </div>
                            <div class="col-lg-2 col-md-3 col-sm-3 col-6"
                                 v-for="color in item.colours"
                                 :key="color.id"
                                 @click="selectColors(color)"
                            >
                                <div class="box">
                                    <!--<img class="box__img" :src="color.image" alt="color.colour_name" v-if="color.image">-->
                                    <div class="colours colour_box">
                                        <v-lazy-image-plugin :src="color.image" v-if="color.image"/>
                                        <p v-else-if="color.hexcode">
                                            <svg-icon name="palette" class="large_icon box__img"
                                                               :style="{color: color.hexcode}"/>
                                        </p>
                                        <p v-if="!color.image && !color.hexcode">
                                            <svg-icon name="palette" class="large_icon box__img"/>
                                        </p>
                                    </div>
                                    <p class="text_default">{{color.colour_name}}</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div class="modal modal_wrap" v-if="warning">
                        <div class="modal_popup">
                            <p class="text text_white">Chosen colour requires additional price. Please, click "I Accept"
                                button if you want to continue.</p>
                            <div class="justify-content-between">
                                <button class="btn btn_transparent" @click="warning = !warning"><svg-icon name="solid/step-backward" /> Back</button>
                                <button class="btn btn_transparent" @click="accept"><svg-icon name="solid/check" /> I Accept</button>
                            </div>
                        </div>
                    </div>
                </div>
  </Modal>
</template>

<script>
    import {searchColors} from "../../helpers/search";
    import {mapGetters, mapActions, mapMutations, mapState} from 'vuex';
    import VLazyImagePlugin from "v-lazy-image";
    import {defineAsyncComponent} from "vue";
    const Modal =  defineAsyncComponent(() => import('@/components/modals/Generic'));

    export default {
        name: "OptionColours",
        data() {
            return {
                color: '',
                warning: false,
                colorItem: {},
            }
        },
        components: {VLazyImagePlugin, Modal},
        methods: {
            selectColors(color, f) {
                this.$store.commit('optionColours/selectedColour', color)
                this.$emit('onSelect', {colour: color, is_same_as_frame: f});

            },

            accept() {
                this.warning = !this.warning;
            },
            closePopup() {
              this.showBackButton = false;
                this.$store.commit('optionColours/openModal', false);
            }
        },
        watch: {
            color(n) {
                this.$store.commit('optionColours/filtered', searchColors(n, this.allColors));
            }
        },
        computed: {

            ...mapGetters({}),

            allColors() {
                return this.$store.getters['optionColours/colors'];
            },
            colors() {
                return this.$store.getters['optionColours/filtrated'];
            },
            defaultColors() {
                var dd = []
                if (this.$store.getters['optionColours/defaultColors'] && this.$store.getters['optionColours/defaultColors'].colours)
                    dd = this.$store.getters['optionColours/defaultColors'].colours
                var sys_def = []
                if (dd.filter(c => {return c.is_same_as_frame}).length > 0)
                    sys_def = Object.values(this.colors).flatMap(i => i.colours).filter(c => {return c && c.is_system_default && dd.filter(cc => {return cc.id === c.id}) == 0})
                return dd.concat(sys_def);
            },
          getColoursAvailableItems() {
              return _.filter(this.colors,(item) => {
                return item.colours
              })
          }
        },

        mounted() {
        },

        created() {
        },

    }
</script>

<style scoped lang="scss">

    @import "../../../src/assets/scss/utils/vars";

    .title {
        text-transform: uppercase;
    }

    .box {
        margin-bottom: 1rem;
        &__img {
            position: static;
            display: block;
            margin: 0 auto;
            margin-bottom: 20px;
            width: 100%;
            max-width: 160px;
            height: 100px !important;
        }
        p {
            text-align: center;
        }
    }

    .form {
        position: relative;
        .btn_link {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            cursor: pointer;
            svg {
                color: $green;
            }
        }

        &__input {
            padding-right: 40px;
            border: 2px solid #0b3553;
        }

    }

    .justify-content-between {
        display: flex;
    }

    .action-btn{
      display: flex;
      color: #ffffff;
      cursor: pointer;
      transition: all .3s;
      :hover {
        color: $green;
      }

      .lg{
        font-size: 2em;
      }
    }


</style>
